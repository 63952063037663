<template>
  <footer class="footer">
    <div>
      <div class="title is-5 is-spaced has-text-centered">
        創業大正３年<br>安らぎのお布団で快適な睡眠を
      </div>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <div class="columns is-centered">
            <a class="column is-narrow has-text-dark" @click="pushPage('company')">企業概要</a>
            <a class="column is-narrow has-text-dark" @click="pushPage('inquiry')">お問い合わせ</a>
            <a class="column is-narrow has-text-dark" @click="pushPage('faq')">よくある質問</a>
            <a class="column is-narrow has-text-dark" @click="pushPage('access')">交通アクセス</a>
            <a class="column is-narrow has-text-dark" @click="pushPage('privacy_policy')">プライバシーポリシー</a>
            <a class="column is-narrow has-text-dark" @click="pushPage('tokushoho')">特商法に基づく表記</a>
          </div>
        </div>
      </div>
      <div class="has-text-centered">
        <p class="is-size-4 is-spaced title mt-5">ふとんのおぐら屋</p>
        <p class="is-size-6">大阪府大阪市旭区森小路２丁目９－２
        </p>
        <p class="is-size-6">TEL 06-6954-1515</p>
        <p class="content"><small>&copy; Oguraya Co.,Ltd.</small></p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    pushPage(page) {
      this.$router.push(page);
    }
  }
}
</script>

<style scoped>
/*  * {
    width: 100%;
    margin:0;
    padding:0;

  }
outline: 2px red solid;*/
/*
  footer {
    margin-top: 30px;
    background-color: #C21732;
    color: #FCFAF2;
    left:0px;
    text-align: center;
  }
  */
  footer {
    /* margin-top: 30px;*/
    background-color: #FCFAF2;
    /* border-top: 7px double #C21732;*/
    left:0px;
    text-align: center;
    padding-bottom: 4rem;
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 2px 0 0 0;
    display: flex;
    flex-wrap: nowrap;
  }
  li{
    width: 100%;
    font-size: 14px;
    padding: 2px 0 0 0;
    text-align: center;
    vertical-align: middle;
  }
</style>

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/__pagelist',
    name: 'PageList',
    component: () => import(/* webpackChunkName: "chunk" */ '../components/PageList.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../components/index.vue')
  },
  {
    path: '/item',
    name: 'item_a',
    component: () => import('../components/item_a.vue')
  },
  {
    path: '/dq5bwakca597w9t9dfk3',
    name: 'dregist',
    component: () => import('../components/dregist.vue')
  }
]

const vues = [
  "tailor_made",
  "maintenance",
  "company",
  "access",
  "inquiry",
  "inquiry_complete",
  "application",
  "column",
  "faq",
  "goodsleep",
  "items",
  "item_detail",
  "cart",
  "order_customer",
  "order_delivery",
  "order_confirm",
  "order_complete",
  "mypage",
  "maintenance_list",
  "check_result",
  "login",
  "regist",
  "column_regist",
  "column_confirm",
  "order_search",
  "order_download",
  "maintenance_search",
  "maintenance_download",
  "customer_search",
  "customer_download",
  "item_maintenance",
  "privacy_policy",
  "tokushoho",
  "forget_pass"
]

for (let _path of vues) {
  routes.push({
    path: '/'+_path,
    name: _path,
    component: () => import('../components/'+_path+'.vue')
  });
}
routes.push({
  path: '/:notFound(.*)',
  redirect: '/'
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { createApp } from 'vue'

import { initializeApp } from 'firebase/app';
import firebaseConfig from './firebase.config'
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
//import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);

import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')

import VueTheMask from 'vue-the-mask'
import axios from 'axios'

import VueGtag from 'vue-gtag'

import App from './App.vue'
import router from './router'
import store from './store'
import aheader from './components/header.vue'
import afooter from './components/footer.vue'

// Initialize Vue
const app = createApp(App);
/*const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lc27bsgAAAAAJSf98xPJkDMYhW8Qb4XK76VS3-z'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});*/
//app.config.globalProperties.$fireauth=fireauth;
app.config.globalProperties.$firebase=firebase;
app.config.globalProperties.$firestore_db=getFirestore(firebase);
app.config.globalProperties.$analytics=analytics;
app.config.globalProperties.$dayjs=dayjs;

app.config.globalProperties.$axios=axios;
//app.config.globalProperties.$appCheck=appCheck;
app.use(VueTheMask);
app.use(store);
app.use(router);
app.use(VueGtag, {
  config: {
    id: 'G-MHYT35663J'
  }
}, router)
app.component('aheader', aheader);
app.component('afooter', afooter);
app.mount('#app');

//テスト環境
/*
export default {
  apiKey: "AIzaSyD9SXwrXjJt4fqomGDdZtXrBAAADGd5kqw",
  authDomain: "oguraya-test1.firebaseapp.com",
  projectId: "oguraya-test1",
  storageBucket: "oguraya-test1.appspot.com",
  messagingSenderId: "1018715610997",
  appId: "1:1018715610997:web:b0468b927b4a32e59f7d3c",
  measurementId: "G-QZ9BF65TG9"
};
*/

//本番環境
export default {
  apiKey: "AIzaSyBAOYy48o_XonZ_1xyYMGs54UaQnYCi9VA",
  authDomain: "oguraya-22f2d.firebaseapp.com",
  projectId: "oguraya-22f2d",
  storageBucket: "oguraya-22f2d.appspot.com",
  messagingSenderId: "77046446368",
  appId: "1:77046446368:web:cb9e4ddf3201704cf4ff45",
  measurementId: "G-MHYT35663J"
};

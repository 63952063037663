<template>
<div class="container is-fulled">
  <div class="navbar is-fixed-top is-white" role="navigation">
    <div class="navbar-brand">
      <a class="navbar-item" @click="pushPage('index')">
        <figure class="image ml-1">
          <img class="logo" src="@/assets/oguraya_logo.png" >
        </figure>
      </a>
      <a role="button" class="navbar-burger" aria-label="menu"  data-target="navbarBasicExample" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

    </div>

    <div id="navbarBasicExample" class="navbar-menu is-variable is-0-mobile" v-bind:class="{ 'is-active' : showNav }">
      <div class="navbar-start">
        <a class="navbar-item"  @click="pushPage('tailor_made')">
          オーダーメイドふとん
        </a>

        <a class="navbar-item" @click="pushPage('maintenance')">
          ふとんの点検
        </a>

        <a class="navbar-item" @click="pushPage('column')">
          コラム
        </a>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            ぐっすりーぷ
          </a>

          <div class="navbar-dropdown">
            <a class="navbar-item" @click="pushPage('goodsleep')">
              ぐっすりーぷとは
            </a>
            <a class="navbar-item" @click="pushPage('item')">
              ふとん・まくら
            </a>
            <a class="navbar-item" @click="pushPage('items')" v-if="$store.state.account_info.dFlg">
              檀家様向け
            </a>
          </div>
        </div>

        <a class="navbar-item" @click="pushPage('cart')">
          <span class="icon-text">
            <span>
              カート
            </span>
            <span class="icon">
              <div class="fa-xl">
                <span class="fa-layers fa-fw">
                  <i class="fa-solid fa-cart-shopping"></i>
                  <span class="fa-layers-counter" v-if="length > 0">{{ Zenkaku }}</span>
                </span>
              </div>
            </span>
          </span>
        </a>


      </div>

      <div class="navbar-end">
        <div class="navbar-item" v-if="$store.state.account_info.eMail!=''">
          <span class="icon-text">
            <span class="icon">
              <i class="fa-solid fa-circle-user"></i>
            </span>
            <strong>{{$store.state.account_info.familyName}}{{$store.state.account_info.firstName}}様</strong>
          </span>
        </div>

        <div class="columns is-centered">
          <div class="navbar-item">
            <div class="column is-half">
              <template v-if="$store.state.account_info.eMail==''">
                <div class="button is-outlined is-success" @click="goLogin()">
                  <strong>ログイン</strong>
                   <span class="icon">
                    <i class="fa-solid fa-circle-user"></i>
                   </span>
                 </div>
              </template>
              <template v-else>
                <div class="button is-outlined is-success" @click="logout()">
                  <strong>ログアウト</strong>
                  <span class="icon">
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                  </span>
                </div>
              </template>
            </div>
            <div class="column is-5">
              <div class="button is-outlined is-danger" @click="pushPage('application')">
                <span>
                  来店予約
                </span>
                <span class="icon">
                  <i class="fa-solid fa-phone-flip"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="navbar-item is-hidden-desktop" @click="pushPage('company')">
        企業概要
      </a>
      <a class="navbar-item is-hidden-desktop" @click="pushPage('inquiry')">
        お問い合わせ
      </a>
      <a class="navbar-item is-hidden-desktop" @click="pushPage('faq')">
        よくある質問
      </a>
      <a class="navbar-item is-hidden-desktop" @click="pushPage('access')">
        交通アクセス
      </a>
      <a class="navbar-item is-hidden-desktop" @click="pushPage('privacy_policy')">
        プライバシーポリシー
      </a>
      <a class="navbar-item is-hidden-desktop" @click="pushPage('tokushoho')">
        特商法に基づく表記
      </a>
    </div>
  </div>
</div>
</template>

<script>
  import { getAuth, signOut } from 'firebase/auth';
  export default {
    data() {
      return {
        showNav: false,
        len: this.$store.getters.cartLength,
        num: ["１", "２", "３", "４", "５", "６", "７", "８", "９", "０"]
      }
    },
    computed: {
      length() {
        //console.log(this.len)
        return this.$store.getters.cartLength
      },
      Zenkaku() {
        let num = this.$store.getters.cartLength
        num = this.replaceToZenkaku(num)
        //console.log(num)
        return num
      }
    },
    methods: {
      goLogin() {
        this.$router.push('/login');
      },
      logout() {
        this.sign_out();
        this.$router.push('/login');
      },
      async sign_out() {
        signOut(getAuth(this.$firebase));
        this.$store.commit('clear4logout')
      },
      pushPage(page) {
        this.$router.push(page);
      },
      replaceToZenkaku(num) {
        return num.toString().replace(/[A-Za-z0-9]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
        });
      }
    }
  }
</script>

<style scoped>
.logo {
  max-width: 133px;
}
.container {
  min-height: 25px;
}
.navbar {
  border-bottom: 3px solid #C21732;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
/*.navbar-menu {
  overflow-x: visible;
  overflow-y: scroll;
}*/
/*  * {
    width: 100%;
    margin:0;
    padding:0;

  }
/*outline: 2px red solid;*/
/*
  header {
    position: fixed;
    top: 0px;
    left:0px;
    display: flex;
    align-items: center;
  }

  header {
    display: flex;
    align-items: center;
  }


  ul{
    list-style: none;
    padding: 2px 0 0 0;
    display: flex;
    flex-wrap: nowrap;
  }
  li{
    font-family: HGSSoeiPresenceEB;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
  }
*/
</style>

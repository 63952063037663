import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    cartList: [],
    totalPrice: 0,
    account_info: {
      familyName: "",
      firstName: "",
      kanaFamily: "",
      kanaFirst: "",
      birthYear: "",
      birthMonth: "",
      birthDay: "",
      picked: "",
      postCode: "",
      pref:"",
      address: "",
      tel: "",
      eMail: "",
      dFlg: false
    },
    order_customer : {
      familyName: "",
      firstName: "",
      kanaFamily: "",
      kanaFirst: "",
      birthYear: "",
      birthMonth: "",
      birthDay: "",
      picked: "",
      postCode: "",
      pref:"",
      address: "",
      tel: "",
      eMail: "",
      delivery: "shop",
      receipt: "",
      sendInfo: true,
      sendName: "",
      sendPostCode: "",
      sendPref:"",
      sendAddress: "",
      sendTel: "",
      sendCharge: 0,
      receiptName: ""
    },
    order_id: "",
    doingPayment: false,
    stripeClientSecret: "",
    inquiryID: ""
  },
  getters: {
    totalPrice(state) {
      let _totalPrice = 0
      state.cartList.forEach((item) => {
        //console.log(item)
        _totalPrice += item.value * item.count;
      });
      return _totalPrice
    },
    cartLength(state) {
      return state.cartList.length
    },
    itemValue:(state) => (index) => {
      return state.cartList[index].value
    }
  },
  mutations: {
    clear4logout(state) {
      state.account_info.familyName = "";
      state.account_info.firstName = "";
      state.account_info.kanaFamily = "";
      state.account_info.kanaFirst = "";
      state.account_info.birthYear = "";
      state.account_info.birthMonth = "";
      state.account_info.birthDay = "";
      state.account_info.picked = "";
      state.account_info.postCode = "";
      state.account_info.pref = "";
      state.account_info.address = "";
      state.account_info.tel = "";
      state.account_info.eMail = "";
      state.account_info.dFlg = false;
      state.order_customer.familyName = "";
      state.order_customer.firstName = "";
      state.order_customer.kanaFamily = "";
      state.order_customer.kanaFirst = "";
      state.order_customer.birthYear = "";
      state.order_customer.birthMonth = "";
      state.order_customer.birthDay = "";
      state.order_customer.picked = "";
      state.order_customer.postCode = "";
      state.order_customer.pref = "";
      state.order_customer.address = "";
      state.order_customer.tel = "";
      state.order_customer.eMail = "";
      state.order_customer.delivery = "shop";
      state.order_customer.receipt = "";
      state.order_customer.sendInfo = true;
      state.order_customer.sendName = "";
      state.order_customer.sendPostCode = "";
      state.order_customer.sendPref = "";
      state.order_customer.sendAddress = "";
      state.order_customer.sendTel = "";
      state.order_customer.sendCharge = 0;
      state.order_customer.receiptName = "";
      state.order_id = "";
      state.doingPayment = false;
      state.stripeClientSecret = "";
      state.inquiryID = "";
      state.cartList = [];
    },
    clear4ordered(state) {
      state.order_customer.delivery = "shop";
      state.order_customer.receipt = "";
      state.order_customer.sendInfo = true;
      state.doingPayment = false;
      state.stripeClientSecret = "";
      state.cartList = [];
    },
    set4login(state, data) {
      state.account_info.familyName = data["姓（漢字）"];
      state.account_info.firstName = data["名（漢字）"];
      state.account_info.kanaFamily = data["姓（カタカナ）"];
      state.account_info.kanaFirst = data["名（カタカナ）"];
      state.account_info.birthYear = data["生年月日（年）"];
      state.account_info.birthMonth = data["生年月日（月）"];
      state.account_info.birthDay = data["生年月日（日）"];
      state.account_info.picked = data["性別"];
      state.account_info.postCode = data["郵便番号"];
      state.account_info.pref = data["住所（県）"];
      state.account_info.address = data["住所"];
      state.account_info.tel = data["電話番号"];
      state.account_info.eMail = data["email"];
      state.account_info.dFlg = data["檀家フラグ"];
      //order用
      state.order_customer.familyName = data["姓（漢字）"];
      state.order_customer.firstName = data["名（漢字）"];
      state.order_customer.kanaFamily = data["姓（カタカナ）"];
      state.order_customer.kanaFirst = data["名（カタカナ）"];
      state.order_customer.birthYear = data["生年月日（年）"];
      state.order_customer.birthMonth = data["生年月日（月）"];
      state.order_customer.birthDay = data["生年月日（日）"];
      state.order_customer.picked = data["性別"];
      state.order_customer.postCode = data["郵便番号"];
      state.order_customer.pref = data["住所（県）"];
      state.order_customer.address = data["住所"];
      state.order_customer.tel = data["電話番号"];
      state.order_customer.eMail = data["email"];
    },
    addItem(state, index) {
      state.cartList[index].count ++;
    },
    reduceItem(state, index) {
      state.cartList[index].count --;
    },
    deleteItem(state, items) {
      state.cartList = state.cartList.filter(function( item ) {
        if (item.name == items[0] && item.kind == items[1]) {
          return false
        } else {
          return true
        }
      });
      //console.log(state.cartList)
    },
    addtoCart(state, item) {
      //console.log("additem", item)
      let flg = false
      for (let i=0; i<state.cartList.length; i++) {
        if (state.cartList[i].name == item.name && state.cartList[i].kind == item.kind) {
          state.cartList[i].count ++;
          flg = true
        }
      }
      if (!flg) {
        item["cart_id"] = state.cartList.length
        state.cartList.push(item)
      }

      /*
      console.log("cart", state.cartList)
      console.log("additem", item)
      let booler = false
      let cartIndex = ""
      state.cartList.forEach((cartItem) => {
        if (cartItem.name == item.name && cartItem.kind == item.kind) {
          booler = true;
          cartIndex = item.id;
          console.log("id", item, item.id)
        }
      });
      if (booler == true) {
        state.cartList[cartIndex].count ++;
        console.log("++")
      } else {
        item["id"] = state.cartList.length
        state.cartList.push(item)
      }
      console.log("add", state.cartList)
      */
    },
    //setKind(state, kinds) {
      //kinds.forEach((item, index) => {
        //if (item != "") {
          //state.cartList[index].kind = item;
        //}
      //})
    //},
    changeValue(state, item) {
      let index = item[0]
      let changedKind = item[1]
      state.cartList[index].kind = changedKind
      state.cartList[index].value = state.cartList[index].kindsList[changedKind]
      // item = [index, kinds]
    },
    setPref(state, pref) {
      state.order_customer.pref = pref
    },
    setDoingPayment(state, flg) {
      state.doingPayment = flg;
    },
    setStripeClientSecret(state, secret) {
      state.stripeClientSecret = secret;
    },
    setIquryID(state, id) {
      state.inquiryID = id;
    }
  },
  actions: {
    addCartList({state}) {
      console.log(state)
    }
  },
  modules: {
  }
})
